import { Box, Image, VStack } from "@chakra-ui/react";
import React, { createContext, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { images } from "./assets/images/images";
import Course from "./components/Course";
import NoMatch from "./components/NoMatch";
import Spinner from "./components/Spinner";
import { ILoadingContext } from "./constants/types";

export const LoadingContext = createContext<ILoadingContext>(
  {} as ILoadingContext
);

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <Box minH={"100vh"}>
        <LoadingContext.Provider value={{ loading, setLoading }}>
          <VStack
            alignItems={"center"}
            spacing={8}
            textAlign="center"
            py={10}
            px={6}
          >
            <Image src={images.logo} h="100px" mb={8} />
            <BrowserRouter>
              <Routes>
                <Route path=":courseId" element={<Course />} />
                <Route path="404" element={<NoMatch />} />
                <Route path="*" element={<Navigate to="/404" />} />
              </Routes>
            </BrowserRouter>
          </VStack>
        </LoadingContext.Provider>
      </Box>
      {loading && <Spinner />}
    </>
  );
};

export default App;
