import {
  Heading,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingContext } from "../App";
import { ErpService } from "../services/erp.service";
import CohortsTable from "./CohortsTable";
import PasswordModal from "./PasswordModal";

const Course: React.FC = () => {
  const { setLoading } = useContext(LoadingContext);
  const [courseData, setCourseData] = useState<any>(null);
  const [permitted, setPermitted] = useState<boolean>(false);

  const navigate = useNavigate();
  const params = useParams();

  const erp_service = new ErpService();

  const {
    isOpen: isPasswordModalOpen,
    onOpen: onPasswordModalOpen,
    onClose: onPasswordModalClose,
  } = useDisclosure();

  useEffect(() => {
    setLoading(true);
    fetchCourse(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCourse: (pwdPrompt: boolean) => void = (pwdPrompt) => {
    erp_service
      .getCourse(params.courseId!)
      .then((courseResponse: any) => {
        setCourseData(courseResponse.data.data);
        setLoading(false);
        pwdPrompt && onPasswordModalOpen();
      })
      .catch((err: Error | AxiosError) => {
        if (err.name === "Network Error") {
          console.error(err);
          navigate("/404");
        }
      });
  };

  /**
   *
   * @param typedPassword password inserted by user
   * @returns true if password matches or false if not
   */
  const checkCoursePassword = (typedPassword: string): boolean => {
    if (typedPassword === courseData?.course_key) {
      setPermitted(true);
      return true;
    } else {
      setPermitted(false);
      return false;
    }
  };

  return (
    <>
      {!isPasswordModalOpen && permitted && courseData ? (
        <VStack spacing={8} divider={<StackDivider borderColor="gray.200" />}>
          <VStack spacing={0}>
            <Text fontSize="xs">{courseData.name}</Text>
            <Heading as="h1" size="lg">
              {courseData.course_name}
            </Heading>
          </VStack>
          <CohortsTable course={courseData} fetchCourse={fetchCourse} />
        </VStack>
      ) : (
        <></>
      )}
      <PasswordModal
        isModalOpen={isPasswordModalOpen}
        onModalClose={onPasswordModalClose}
        passwordCheckHandler={checkCoursePassword}
      />
    </>
  );
};

export default Course;
