import {
  NumberInput,
  NumberInputField,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useContext, useState } from "react";
import { LoadingContext } from "../App";
import { ErpService } from "../services/erp.service";

const CohortsTable: React.FC<{
  course: any;
  fetchCourse: (pwdPrompt: boolean) => void;
}> = ({ course, fetchCourse }) => {
  const { setLoading } = useContext(LoadingContext);
  const [invalidInputsIds, setInvalidInputsIds] = useState<string[]>([]);
  const toast = useToast();

  const erp_service = new ErpService();

  const onCohortAssisanceInputChange: (
    event: React.FocusEvent<HTMLInputElement, Element>,
    facility: any,
    cohort: any
  ) => void = (event, facility, cohort) => {
    if (event.currentTarget.value !== "") {
      setLoading(true);
      const assistance_update_toast = toast({
        title: "Se está actualizando el número de asistentes introducido",
        status: "info",
        duration: 2000,
        isClosable: true,
      });

      erp_service
        .updateAssistance(
          parseInt(event.currentTarget.value),
          course,
          facility.facility,
          `${cohort.cohort_start_date}|${cohort.cohort_end_date}`
        )
        .then((res) => {
          setLoading(false);

          setInvalidInputsIds(
            invalidInputsIds.filter((i) => i !== `${event.target.name}`)
          );

          toast({
            title: "Se ha actualizado el número de asistentes",
            status: "success",
            duration: 2000,
            isClosable: true,
          });

          fetchCourse(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);

          setInvalidInputsIds(
            invalidInputsIds.concat([`${event.target.name}`])
          );

          toast({
            title: `${err}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

          fetchCourse(false);
        })
        .finally(() => toast.close(assistance_update_toast));
    }
  };

  return (
    <TableContainer>
      <Table variant="simple" colorScheme="gray">
        <Thead>
          <Tr>
            <Th minW={"sm"}>Establecimiento</Th>
            <Th colSpan={course.cohorts.length} textAlign="center">
              Convocatorias
            </Th>
            <Th textAlign={"center"}>Total</Th>
          </Tr>
          <Tr bgColor={"gray.100"}>
            <Th></Th>
            {course.cohorts.map((cohort: any) => {
              const moment_start_date = moment(
                cohort.cohort_start_date,
                "YYYY-MM-DD HH:mm:ss"
              );
              const moment_end_date = moment(
                cohort.cohort_end_date,
                "YYYY-MM-DD HH:mm:ss"
              );
              const date = moment_start_date.format("ddd DD-MM");
              const start_time = moment_start_date.format("HH:mm");
              const end_time = moment_end_date.format("HH:mm");

              return (
                <Th textAlign={"center"} key={`cohort-${cohort.name}`}>
                  <>
                    {date}
                    <br />
                    {start_time}
                    {" - "}
                    {end_time}
                  </>
                </Th>
              );
            })}
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody fontSize="sm">
          {course.facilities.map((facility: any) => (
            <Tr key={`facility-${facility.name}`}>
              <Td>
                <b>{facility.facility}</b>
              </Td>
              {course.cohorts.map((cohort: any) => (
                <Td key={`cohort-${cohort.name}`}>
                  <NumberInput
                    name={`${cohort.name}-${facility.name}`}
                    size={"sm"}
                    w={"fit-content"}
                    m={"0 auto"}
                    min={0}
                    max={course.max_assistants_per_cohort}
                    step={1}
                    defaultValue={erp_service.getCohortFacilityAssistants(
                      course,
                      `${cohort.cohort_start_date}|${cohort.cohort_end_date}`,
                      facility.facility
                    )}
                    isInvalid={invalidInputsIds.includes(
                      `${cohort.name}-${facility.name}`
                    )}
                    onBlur={(e) =>
                      onCohortAssisanceInputChange(e, facility, cohort)
                    }
                  >
                    <NumberInputField
                      size={4}
                      pl={0}
                      pr={0}
                      textAlign="center"
                      borderColor={"gray.400"}
                    />
                  </NumberInput>
                </Td>
              ))}
              <Th textAlign={"center"}>
                {`${erp_service.getFacilityTotalAssistants(
                  course,
                  facility.facility
                )} asistentes`}
              </Th>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Total</Th>
            {course.cohorts.map((cohort: any) => (
              <Th
                textAlign={"center"}
                bgColor={
                  erp_service.getCohortTotalAssistants(
                    course,
                    `${cohort.cohort_start_date}|${cohort.cohort_end_date}`
                  ) >= course.max_assistants_per_cohort
                    ? "red.200"
                    : "unset"
                }
                key={`cohort-${cohort.name}`}
              >
                {`${erp_service.getCohortTotalAssistants(
                  course,
                  `${cohort.cohort_start_date}|${cohort.cohort_end_date}`
                )}/${course.max_assistants_per_cohort} asistentes`}
              </Th>
            ))}
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default CohortsTable;
