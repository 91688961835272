import axios, { AxiosResponse } from "axios";
import {
  ERPNEXT_API_KEY,
  ERPNEXT_SECRET_API_KEY,
  ERP_ENDPOINT,
} from "../constants/env";

export class ErpService {
  /**
   * Fetch course (Curso Practico) from ERP
   *
   * @param courseId ERP course name
   *
   * @returns Promise with course request response
   */
  public getCourse: (courseId: string) => Promise<AxiosResponse<any, any>> = (
    courseId
  ) => {
    return axios.get(
      `${ERP_ENDPOINT}/api/resource/Curso%20Practico/${courseId}`,
      {
        headers: {
          Authorization: `token ${ERPNEXT_API_KEY}:${ERPNEXT_SECRET_API_KEY}`,
        },
        params: {
          fields: JSON.stringify(["*"]),
          limit_page_length: 0,
        },
      }
    );
  };

  /**
   * Get facility assistants for a cohort
   *
   * @param course Object with complete course data
   * @param cohort String for identifying the cohort
   * @param facility Name of the facility
   *
   * @returns Number of assistants for a facility and cohort
   */
  public getCohortFacilityAssistants: (
    course: any,
    cohort: string,
    facility: string
  ) => number = (course, cohort, facility) => {
    let facility_cohort_assistance = course.facilities_assistance.filter(
      (fa: any) => fa.cohort === cohort && fa.facility === facility
    );

    if (facility_cohort_assistance.length)
      return facility_cohort_assistance[0].assistants;
    else {
      return 0;
    }
  };

  /**
   * Get total assistants for a facility
   *
   * @param course Object with complete course data
   * @param facility Name of the facility
   *
   * @returns Number of assistants for a facility
   */
  public getFacilityTotalAssistants: (course: any, facility: string) => number =
    (course, facility) => {
      let facility_assistance = course.facilities_assistance
        .filter((fa: any) => fa.facility === facility)
        .map((fa: any) => fa.assistants)
        .reduce((acum: number, curr: number) => acum + curr, 0);

      return facility_assistance || 0;
    };

  /**
   * Get total assistants for a cohort
   *
   * @param course Object with complete course data
   * @param cohort String for identifying the cohort
   *
   * @returns Number of assistants for a cohort
   */
  public getCohortTotalAssistants: (course: any, cohort: string) => number = (
    course,
    cohort
  ) => {
    let cohort_assistance = course.facilities_assistance
      .filter((fa: any) => fa.cohort === cohort)
      .map((fa: any) => fa.assistants)
      .reduce((acum: number, curr: number) => acum + curr, 0);

    return cohort_assistance || 0;
  };

  /**
   * Update assistance for a cohort and facility
   *
   * @param newAssistantsValue New number of assistants
   * @param course Object with complete course data
   * @param facility Name of the facility
   * @param cohort String for identifying the cohort
   *
   * @returns Update request response
   */
  public updateAssistance: (
    newAssistantsValue: number,
    course: any,
    facility: string,
    cohort: string
  ) => Promise<any> = async (newAssistantsValue, course, facility, cohort) => {
    // Check if number of maximum assistants for cohort would be exceeded
    const actual_cohort_assistants = this.getCohortTotalAssistants(
      course,
      cohort
    );
    const cohort_assistants = this.getCohortFacilityAssistants(
      course,
      cohort,
      facility
    );

    if (
      actual_cohort_assistants + (newAssistantsValue - cohort_assistants) >
      course.max_assistants_per_cohort
    ) {
      throw new Error("Límite de asistentes por sesión superado");
    } else {
      // Check if an entry exists for cohort and facility
      const cohortFacilityAssistanceExists =
        await this.cohortFacilityAssistanceExists(course, facility, cohort);

      // If record exists -> update record assistants
      if (cohortFacilityAssistanceExists) {
        return this.updateCohortFacilityAssistance(
          newAssistantsValue,
          cohortFacilityAssistanceExists
        );
      }
      // If record doesn't exist -> create new assistance record
      else {
        return this.insertCohortFacilityAssistance(
          newAssistantsValue,
          course,
          facility,
          cohort
        );
      }
    }
  };

  /**
   * Check for existing assistance records for facility and cohort
   *
   * @param course Object with complete course data
   * @param facility Name of the facility
   * @param cohort String for identifying the cohort
   *
   * @returns Course ID if exists or null
   */
  cohortFacilityAssistanceExists: (
    course: any,
    facility: string,
    cohort: string
  ) => Promise<string | null> = async (course, facility, cohort) => {
    try {
      const cursoPracticoResponse = await axios.get(
        `${ERP_ENDPOINT}/api/resource/Curso%20Practico/${course.name}`,
        {
          headers: {
            Authorization: `token ${ERPNEXT_API_KEY}:${ERPNEXT_SECRET_API_KEY}`,
          },
          params: {
            fields: JSON.stringify(["*"]),
            limit_page_length: 0,
          },
        }
      );

      const found_assistances =
        cursoPracticoResponse.data.data.facilities_assistance.filter(
          (fa: any) => fa.cohort === cohort && fa.facility === facility
        );

      if (found_assistances.length === 0) {
        return null;
      } else {
        return found_assistances[0].name;
      }
    } catch (error) {
      throw new Error(
        `No se han podido obtener los registros de asistencia: ${error}`
      );
    }
  };

  /**
   * Insert new assistance record for cohort and facility
   *
   * @param newAssistantsValue New number of assistants
   * @param course Object with complete course data
   * @param facility Name of the facility
   * @param cohort String for identifying the cohort
   *
   * @returns Promise with create request
   */
  insertCohortFacilityAssistance: (
    newAssistantsValue: number,
    course: any,
    facility: string,
    cohort: string
  ) => Promise<boolean> = (newAssistantsValue, course, facility, cohort) => {
    return axios.post(
      `${ERP_ENDPOINT}/api/resource/Curso%20Practico%20Registro%20Asistencia`,
      {
        facility,
        assistants: newAssistantsValue,
        cohort,
        parent: course.name,
        parentfield: "facilities_assistance",
        parenttype: "Curso Practico",
      },
      {
        headers: {
          Authorization: `token ${ERPNEXT_API_KEY}:${ERPNEXT_SECRET_API_KEY}`,
        },
      }
    );
  };

  /**
   * Update existing assistance record for cohort and facility
   *
   * @param newAssistantsValue New number of assistants
   * @param assistanceRecordId Assistance record ID
   *
   * @returns Promise with update request
   */
  updateCohortFacilityAssistance: (
    newAssistantsValue: number,
    assistanceRecordId: string
  ) => Promise<boolean> = (newAssistantsValue, assistanceRecordId) => {
    return axios.put(
      `${ERP_ENDPOINT}/api/resource/Curso%20Practico%20Registro%20Asistencia/${assistanceRecordId}`,
      {
        assistants: `${newAssistantsValue}`,
      },
      {
        headers: {
          Authorization: `token ${ERPNEXT_API_KEY}:${ERPNEXT_SECRET_API_KEY}`,
        },
      }
    );
  };
}
