import { Box, Spinner as ChakraSpinner } from "@chakra-ui/react";

const Spinner = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      pos={"absolute"}
      w={"full"}
      h={"full"}
      top={0}
      left={0}
      backgroundColor={"rgba(0, 0, 0, 0.2)"}
    >
      <ChakraSpinner
        thickness="3px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Box>
  );
};

export default Spinner;
