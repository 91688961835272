import { ChakraProvider } from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/es";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

moment.locale("es");

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
