import { CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

const NoMatch: React.FC = () => {
  return (
    <>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={"red.500"}
          rounded={"0"}
          w={"155px"}
          h={"55px"}
          textAlign="center"
        >
          <CloseIcon boxSize={"20px"} color={"white"} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        No se ha encontrado el curso
      </Heading>
      <Text color={"gray.500"}>
        La referencia del curso no se ha encontrado. Por favor, indíqueselo a la
        persona de la que recibió el enlace.
      </Text>
    </>
  );
};

export default NoMatch;
