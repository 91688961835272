import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import React, { FormEvent, useState } from "react";

const PasswordModal: React.FC<{
  isModalOpen: boolean;
  onModalClose: () => void;
  passwordCheckHandler: (pwd: string) => boolean;
}> = ({ isModalOpen, onModalClose, passwordCheckHandler }) => {
  const toast = useToast();

  const [passwordInput, setPasswordInput] = useState<string>("");
  const passwordInputInvalid = passwordInput === "";

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (passwordCheckHandler(passwordInput)) {
      onModalClose();
    } else {
      toast({
        title: "Clave incorrecta",
        description: `La clave introducida no es correcta.`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        return;
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"lg"}>
          Introduce la contraseña del curso
        </ModalHeader>
        <ModalBody>
          <form onSubmit={submitHandler}>
            <FormControl id="pwd" isRequired isInvalid={passwordInputInvalid}>
              <Input
                type="text"
                onChange={(e) => setPasswordInput(e.target.value)}
              />
              {passwordInputInvalid && (
                <FormErrorMessage>Introduce una contraseña</FormErrorMessage>
              )}
            </FormControl>{" "}
            <Button
              colorScheme="blue"
              size={"md"}
              w={"full"}
              mt={6}
              mb={4}
              type="submit"
            >
              Enviar
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PasswordModal;
